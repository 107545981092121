/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        function addYoutubeModal(){
    
          $('#YoutubeModal').on('show.bs.modal', function (event) {
            var $midlayer  = $('<div class="youtube-player-wrap embed-responsive embed-responsive-16by9"/>').appendTo('#YoutubeModal .modal-body'); 
            var $video  = $('a.video');
            var button  = $(event.relatedTarget);   // Button that triggered the modal
            var vid  = button.data('youtubeid');  // Extract info from data-youtubeid attributes
            var iframe  = '<iframe />';
            var url  = '//youtube.com/embed/'+vid+'?autoplay=1&autohide=1&modestbranding=1&rel=0&hd=1'; // To turn off Auto play, set autoplay=0
            var widthF = '100%';
            var heightF  = 400;
            var frameborder = 0;
            $(iframe, {
              name: 'videoframe',
              id : 'videoframe',
              src : url,
              frameborder: 0,
              class : 'youtube-player embed-responsive-item',
              type : 'text/html',
              allowfullscreen: true
            }).appendTo($midlayer);
          });
      
          $('#YoutubeModal').on('hide.bs.modal', function (e) {
            $('#YoutubeModal div.modal-body').html('');
          });
        }

        function filterMembers(){
          var isMobil = ($('.team-member .txt-wrap.d-block').length && $('.team-member .txt-wrap.d-block').is(':visible')) ? true : false;

          $(document).on('click','.team-list-section .filter-wrap a',function(){
            var id= $(this).attr('href').replace('#','');
            $('.team-list-section .filter-wrap a').removeClass('active');
            $(this).addClass('active');
            $('.team-member').removeClass('inactive');
            if(id === 'sportovci'){
              $('.team-member:not([data-sport-team="1"])').addClass('inactive'); 
            }
            else if(isMobil){
              $('.team-member[data-sport-team="1"]').addClass('inactive'); 
            }
          });
          if(isMobil){
            $('.team-member[data-sport-team="1"]').addClass('inactive'); 
          }
        }

        function addSlideshow(){
          $('.owl-carousel:not(.simple-slideshow)').owlCarousel({
            singleItem : true,
            nav: true,
            dots: false,
            items: 1
          });
          $('.owl-carousel.simple-slideshow').owlCarousel({
            singleItem : true,
            nav: false,
            dots: true,
            items: 1
          });
          $(document.documentElement).keyup(function (event) {    

            var owl = $(".owl-carousel");
        
            // handle cursor keys
            if (event.keyCode === 37) {
               // go left
               owl.trigger('owl.prev');
            } else if (event.keyCode === 39) {
               // go right
               owl.trigger('owl.next');
            }
        
        });
        }

        function addLightbox(){
          $(document).on('click', '[data-toggle="lightbox"]', function(event) {
            event.preventDefault();
            $(this).ekkoLightbox();
          });
        }

        addSlideshow();

        addLightbox();

        filterMembers();

        addYoutubeModal();
        


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
